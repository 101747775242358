body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.results {
  margin:      0.4em;
  padding-top: 0.2em;
}

.result-image {
  border: 1px dashed;
  margin: 0.2em;
  max-width: 100%;
}

.result-video {
  margin: 0.2em;
  max-width: 100%;
}

.results-missing {
  font-style: italic;
  font-size:  80%;
  max-width: 100%;
}

ul, .result-preview {
  list-style-type: none;
  font-family:     monospace;
  font-size:       90%;
}

.App {
  text-align: center;
}

.App-header {
  color:            white;
  background-color: #222;
  margin-bottom:    0.8em;
  position:         relative;
  display:          -webkit-flex;
  display:          flex;
  -webkit-justify-content:  center;
          justify-content:  center;
  height:           56px;
}

.rss-icon {
  position: absolute;
  height:   24px;
  top:      16px;
  right:    16px;
}

.App-title {
  font-size: 1.2em;
}

